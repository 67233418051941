import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { Fade, Slide } from "react-slideshow-image";
import { Image, VStack } from "@chakra-ui/react";
import { colors, izr_server } from "./config";
import EventSlider from "./components/EventSlider";
import IText from "./components/IText";

interface props {
  onEnd: () => void;
}

function HadithSlider({ onEnd }: props) {
  const [Hadith, setHadith] = useState<[{ hadith: string; data: string }]>([
    { hadith: "", data: "" },
  ]);
  const [Opacity, setOpacity] = useState("0");

  useEffect(() => {
    setTimeout(() => {
      setOpacity("1");
    }, 1000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      onEnd();
    }, 40000);
  }, []);

  useEffect(() => {
    const asfn = async () => {
      await axios.get(izr_server + "/tasks/hadith/").then((response) => {
        let hadith = [];

        console.log(response.data);
        hadith.push({
          hadith: response.data.hadith_ar,
          data: response.data.data_ar,
        });
        hadith.push({
          hadith: response.data.hadith_de,
          data: response.data.data_de,
        });
        setHadith(hadith as any);
        console.log(hadith);
      });
    };
    asfn();
  }, []);
  function wordsLen(str: string) {
    const array = str.trim().split(/\s+/);
    return array.length;
  }
  return (
    <Fade
      easing="ease" // duration={5000}
      duration={20000}
      arrows={false}
    >
      {Hadith &&
        Hadith.map((hadith, index) => (
          <div
            className=""
            style={{
              height: "100vh",
              // width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1vw",
            }}
          >
            <IText
              style={{
                fontSize: "4vw",
                textAlign: "center",
                direction: "rtl",
                color: "white",
                fontWeight: "500",
                background: colors.grad,
                padding: "5vw",
                borderRadius: "5vw",
              }}
            >
              {hadith.hadith}
              <br />
              {hadith.data}
            </IText>
          </div>
        ))}
    </Fade>
  );
}

export default HadithSlider;
