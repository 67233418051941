import React, { useEffect, useState } from "react";
import Text from "./IText";
import {
  Button,
  DrawerOverlay,
  Grid,
  GridItem,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { colors } from "../config";
import Prayer from "./Prayer";
import {
  DayPrayerTimes,
  FetchAndStorePrayerTimes,
  FetchCurrentDayPrayerTimes,
  FetchIqamaTimes,
  GetIqamaTimes,
  LoadItem,
  StoreItem,
  getNextPrayerTime,
  getTime,
} from "../toolsfn";
import IZR from "./IZR";
import TimeDateInfo from "./TimeDateInfo";
import EventSlider from "./EventSlider";
import DigitalClock from "./DigitalClock";

interface props {
  GoToEvents: (what: string) => void;
}

const PrayerTimes = ({ GoToEvents }: props) => {
  const [TodayPrayerTimes, setTodayPrayerTimes] =
    useState<DayPrayerTimes | null>(null);
  const [NextPrayer, setNextPrayer] = useState<string | null>(null);
  const [PrayersLayout, setPrayersLayout] = useState<string>(
    "1fr 1fr 1fr 1fr 1fr"
  );
  const [iqamaTimes, setIqamaTime] = useState({
    Asr: 15,
    Dhuhr: 15,
    Fajr: 15,
    Isha: 5,
    Jumaa: 0,
    Maghrib: 5,
    Tarawih: 0,
  });
  useEffect(() => {}, []);
  const FetchTodayPrayerTimes = () => {
    const ptimes: DayPrayerTimes =
      FetchCurrentDayPrayerTimes() as DayPrayerTimes;
    HandleGetNextPrayerTimes(ptimes);
    setTodayPrayerTimes(ptimes);
    const iqamas = GetIqamaTimes();
    setIqamaTime(iqamas);
    console.log("Fetching");
  };

  useEffect(() => {
    FetchTodayPrayerTimes();
  }, []);

  useEffect(() => {
    setInterval(() => {
      const today = new Date();
      const time = today.getHours();
      time === 0 && FetchTodayPrayerTimes();
    }, 5 * 60 * 5000);
  }, []);

  const HandleGetNextPrayerTimes = (ptimes: DayPrayerTimes) => {
    const next = getNextPrayerTime(ptimes);
    console.log("Handling PrayerTimes", next);

    switch (next.prayer) {
      case "Fajr":
        setPrayersLayout("2fr 1fr 1fr 1fr 1fr");
        break;
      case "Dhuhr":
        setPrayersLayout("1fr 2fr 1fr 1fr 1fr");
        break;
      case "Jumaa":
        setPrayersLayout("1fr 2fr 1fr 1fr 1fr");
        break;
      case "Asr":
        setPrayersLayout("1fr 1fr 2fr 1fr 1fr");
        break;
      case "Maghrib":
        setPrayersLayout("1fr 1fr 1fr 2fr 1fr");
        break;
      case "Isha":
        setPrayersLayout("1fr 1fr 1fr 1fr 2fr");
        break;
    }
    setNextPrayer(next.prayer);
  };

  // useEffect(() => {
  //   // TodayPrayerTimes && HandleGetNextPrayerTimes(TodayPrayerTimes);
  //   setNextPrayer("Fajr");
  //   setPrayersLayout("2fr 1fr 1fr 1fr 1fr");
  // }, [NextPrayer]);

  const HandleNextDay = () => {
    console.log("hnalding next day");

    const ptimes: DayPrayerTimes =
      FetchCurrentDayPrayerTimes() as DayPrayerTimes;
    HandleGetNextPrayerTimes(ptimes);
    setTodayPrayerTimes(ptimes);
  };

  const prayer_times = [
    {
      de: "Fajr",
      ar: "الفجر",
      time: TodayPrayerTimes?.Fajr,
      key: "Fajr",
      iqama: iqamaTimes.Fajr,
    },

    {
      de: "Asr",
      key: "Asr",
      ar: "العصر",
      time: TodayPrayerTimes?.Asr,
      iqama: iqamaTimes.Asr,
    },
    {
      de: "Maghrib",
      key: "Maghrib",
      ar: "المغرب",
      time: TodayPrayerTimes?.Maghrib,
      iqama: iqamaTimes.Maghrib,
    },
    {
      de: "Isha",
      key: "Isha",
      ar: "العشاء",
      time: TodayPrayerTimes?.Isha,
      iqama: iqamaTimes.Isha,
    },
  ];

  const prayers =
    new Date().getDay() === 5
      ? [
          ...prayer_times,
          {
            de: "Jumaa",
            key: "Dhuhr",
            ar: "الجمعة",
            time: TodayPrayerTimes?.Jumaa,
            iqama: iqamaTimes.Jumaa,
          },
        ]
      : [
          ...prayer_times,
          {
            de: "Dhuhr",
            key: "Dhuhr",
            ar: "الظهر",
            time: TodayPrayerTimes?.Dhuhr,
            iqama: iqamaTimes.Dhuhr,
          },
        ];

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.5rem",
        boxSizing: "border-box",
      }}
    >
      <Grid
        templateAreas={`"header header header header header"
                  "Fajr Dhuhr Asr Maghrib Isha"
                  "footer footer footer footer footer"`}
        gridTemplateRows={"1fr 8fr 1fr "}
        gridTemplateColumns={PrayersLayout}
        gap="1rem"
        // backgroundColor={colors.primary}
        borderRadius={"1rem"}
        // margin={"1rem"}
        height={"100%"}
        width={"100%"}
        transition={"ease 2s"}
      >
        <GridItem area={"header"}>
          {TodayPrayerTimes && (
            <TimeDateInfo
              data={[TodayPrayerTimes["Hijri"], TodayPrayerTimes["Hijri_ar"]]}
            />
          )}
        </GridItem>
        {prayers.map((prayer) => (
          <GridItem key={prayer.de} area={prayer.key} transition={"ease 2s"}>
            <Prayer
              reloadPrayerTimes={HandleNextDay}
              prayer_ar={prayer.ar}
              prayer_de={prayer.de}
              time={prayer.time!}
              next={NextPrayer!}
              iqama={prayer.iqama}
              GetNextPrayerTimes={() =>
                HandleGetNextPrayerTimes(TodayPrayerTimes!)
              }
              GoToEvents={(what) => GoToEvents(what)}
            ></Prayer>
          </GridItem>
        ))}
        <GridItem area={"footer"}>
          <HStack>
            <IZR GoTo={GoToEvents} />
          </HStack>
        </GridItem>
      </Grid>
    </div>
  );
};

export default PrayerTimes;
