export const  izr_fonts = {
    default : "El Messiri"
}

export const izr_server = "https://izr-cloud.online"

export const colors = {
    primary: "#132a13",
    sec: "#90a955",
    grad : "linear-gradient(147deg, #166d3b 0%, #132a13 74%)",
    grad_sec : "linear-gradient(147deg, #eaf4f4 0%, #f6fff8 74%)"
  };
  